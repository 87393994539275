import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navbar: {
      backgroundColor: theme.palette.primary.main,
    },
    navLink: {
      textDecoration: 'none',
      borderBottom: `3px solid ${theme.palette.secondary.main}`,
    },
  })
);

export default useStyles;
