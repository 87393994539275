import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import IUser from 'common/user.interface';
import safeLocalStorage from 'utils/local-storage';

const tokenLocalStorageKey = 'auth';
const savedEmailLocalStorageKey = 'remember_me';

export interface AuthState {
  user: IUser | null;
  isAuthenticated: boolean;
  accessToken: string;
  refreshToken: string;
  emailToRemember: string;
}

function decodeToken(accessToken: string) {
  return jwtDecode(accessToken) as IUser;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  accessToken: '',
  refreshToken: '',
  emailToRemember: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      const decodedToken = decodeToken(accessToken);

      state.user = decodedToken;
      state.isAuthenticated = true;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.accessToken = '';
      state.refreshToken = '';
    },
    rememberEmail: (state, action) => {
      state.emailToRemember = action.payload.email;
    },
    retrieveAuthFromLocalStorage: (state) => {
      const token = safeLocalStorage.get(tokenLocalStorageKey);
      const emailToRemember = safeLocalStorage.get(savedEmailLocalStorageKey);

      if (token) {
        const parsedToken = JSON.parse(token);

        state.user = decodeToken(parsedToken.accessToken);
        state.isAuthenticated = true;
        state.accessToken = parsedToken.accessToken;
        state.refreshToken = parsedToken.refreshToken;
      }

      state.emailToRemember = emailToRemember || '';
    },
  },
});

export const { login, logout, rememberEmail, retrieveAuthFromLocalStorage } = authSlice.actions;

export default authSlice.reducer;

// Funções de ação assíncrona usando o padrão Redux Thunk
export const loginAsync = (token) => async (dispatch) => {
  const mappedToken = { accessToken: token.access_token, refreshToken: token.refresh_token };
  safeLocalStorage.set(tokenLocalStorageKey, JSON.stringify(mappedToken));

  const decodedToken = decodeToken(mappedToken.accessToken);

  dispatch(login({ ...mappedToken, decodedToken }));
};

export const logoutAsync = () => async (dispatch) => {
  safeLocalStorage.remove(tokenLocalStorageKey);
  dispatch(logout());
};

export const rememberEmailAsync = (email) => async (dispatch) => {
  if (email) {
    safeLocalStorage.set(savedEmailLocalStorageKey, email);
  } else {
    safeLocalStorage.remove(savedEmailLocalStorageKey);
  }

  dispatch(rememberEmail({ email }));
};
