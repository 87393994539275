import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';

import AppLayout from 'layout/AppLayout';
import { userHasPermissions } from 'utils/auth';
import PageFallback from 'components/PageFallback';

export const AuthenticatedArea = () => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      navigate('/logout');
    }
  }, [keycloak, initialized]);

  if (!initialized) return <PageFallback />;

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export const PrivateRoute = ({ permittedRoles }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!userHasPermissions(permittedRoles)) {
      navigate('/contratar');
    }
  }, []);

  return <Outlet />;
};
