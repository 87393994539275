export const NAVBAR_HEIGHT = 75;
export const navBarItems = {
  none: [],
  app: [
    {
      label: 'Envio e Contratação',
      href: '/contratar',
    },
    {
      label: 'Contratos e Pagamentos',
      href: '/consulta-contratos',
    },
    {
      label: 'Painel de Controle',
      href: '/admin',
    },
    {
      label: 'Relatórios',
      href: '/relatorios',
    },
  ],
  landing: [
    {
      label: 'Home',
      href: '#main',
    },
    {
      label: 'Como Funciona',
      href: '#como-funciona',
    },
    {
      label: 'Vantagens',
      href: '#vantagens',
    },
    {
      label: 'Sobre',
      href: '#sobre',
    },
  ],
};
