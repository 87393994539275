import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ContractAnalysis } from './ContractAnalysis';

const ControlPanel = lazy(() => import('./ControlPanel'));
const Ctes = lazy(() => import('./Ctes'));
const ProposalsManagement = lazy(() => import('./ProposalsManagement'));
const LimitsConcentrationControl = lazy(() => import('./LimitsConcentrationControl'));
const Leads = lazy(() => import('./Leads'));
const Users = lazy(() => import('./Users'));
const UserDetails = lazy(() => import('./Users/UserDetails'));
const ContractControl = lazy(() => import('./ContractControl'));
const CreditRiskControl = lazy(() => import('./CreditRiskControl'));
const CreateUser = lazy(() => import('./CreateUser'));
const CreateCarrier = lazy(() => import('./CreateCarrier'));
const SecondaryOperation = lazy(() => import('./SecondaryOperation'));
const SecondaryOperationDetails = lazy(() => import('./SecondaryOperation/Details'));
const ReceiptCharge = lazy(() => import('./ReceiptCharge'));
const ReceiptChargeComment = lazy(() => import('./ReceiptCharge/Comment'));
const TakersManagement = lazy(() => import('./TakersManagement'));
const TakerCreate = lazy(() => import('./TakersManagement/Create'));
const TakerEdit = lazy(() => import('./TakersManagement/Edit'));
const RevenueManagement = lazy(() => import('./RevenueManagement'));
const RevenueConciliation = lazy(() => import('./RevenueManagement/Conciliation'));
const CarrierManagement = lazy(() => import('./CarrierManagement'));
const CarrierDetails = lazy(() => import('./CarrierManagement/CarrierDetails'));
const EligibilityCriteriasManagement = lazy(() => import('./Ctes/EligibilityCriteriasManagement'));

const AdminRoutes = () => {
  return (
    <Routes>
      <Route index element={<ControlPanel />} />
      <Route path="risco-credito" element={<CreditRiskControl />} />
      <Route path="processamento-propostas" element={<ProposalsManagement />} />
      <Route path="processamento-propostas/limites-concentracao/:id" element={<ContractAnalysis.LimitsConcentration />} />
      <Route path="processamento-propostas/atipicidade/:id" element={<ContractAnalysis.Atypicality />} />
      <Route path="processamento-propostas/registros/:id" element={<ContractAnalysis.Registers />} />
      <Route path="processamento-propostas/notificacao-tomadores/:id" element={<ContractAnalysis.ContractTakersNotification />} />
      <Route path="leads" element={<Leads />} />
      <Route path="usuarios" element={<Users />} />
      <Route path="usuarios/:id" element={<UserDetails />} />
      <Route path="usuarios/novo" element={<CreateUser />} />
      <Route path="controle-contratacao" element={<ContractControl />} />
      <Route path="controle-limites-concentracao" element={<LimitsConcentrationControl />} />
      <Route path="transportadoras/novo" element={<CreateCarrier />} />
      <Route path="ctes/monitoramento" element={<Ctes />} />
      <Route path="ctes/criterios-elegibilidade" element={<EligibilityCriteriasManagement />} />
      <Route path="operacoes-secundarias/:id" element={<SecondaryOperationDetails />} />
      <Route path="operacoes-secundarias" element={<SecondaryOperation />} />
      <Route path="recebimento-cobranca" element={<ReceiptCharge />} />
      <Route path="recebimento-cobranca/:flag/:id" element={<ReceiptChargeComment />} />
      <Route path="tomadores" element={<TakersManagement />} />
      <Route path="tomadores/novo" element={<TakerCreate />} />
      <Route path="tomadores/:id" element={<TakerEdit />} />
      <Route path="arrecadacao" element={<RevenueManagement />} />
      <Route path="arrecadacao/:revenueId/conciliacao" element={<RevenueConciliation />} />
      <Route path="transportadoras" element={<CarrierManagement />} />
      <Route path="transportadoras/:id" element={<CarrierDetails />} />
    </Routes>
  );
};

export default AdminRoutes;
