import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserRegistry, RegisterStatusTypes } from 'common/user.interface';

export interface IUserState {
  userRegistry: IUserRegistry;
}

const initialState: IUserState = {
  userRegistry: {
    id: 0,
    documentNumber: '',
    name: '',
    phoneNumber: '',
    registerStatusType: RegisterStatusTypes.PENDING,
    email: '',
    isFirstAccess: false,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserRegistry: (state, action: PayloadAction<IUserRegistry>) => {
      state.userRegistry = action.payload;
    },
  },
});

export const { setUserRegistry } = userSlice.actions;

export default userSlice.reducer;
