import { createSlice } from '@reduxjs/toolkit';

export interface NewContractProposalState {
  availableCtes: {
    items: any[];
    selected: any[];
  };
  proposalCtes: {
    items: any[];
    selected: any[];
  };
}

const initialState: any = {
  availableCtes: {
    items: [],
    selected: [],
  },
  proposalCtes: {
    items: [],
    selected: [],
  },
};

const contractSlice = createSlice({
  name: 'newContractProposal',
  initialState,
  reducers: {
    reset: () => {
      return { ...initialState };
    },
    setAvailableCtes: (state: NewContractProposalState, action) => {
      state.availableCtes.items = [...action.payload.filter((cte) => !state.proposalCtes.items.some(({ id }) => id === cte.id))];
    },
    deleteCtes: (state: NewContractProposalState, action) => {
      state.availableCtes.items = [...state.availableCtes.items.filter((cte) => !action.payload.some(({ id }) => id === cte.id))];
      state.availableCtes.selected = [];
    },
    addToProposal: (state: NewContractProposalState) => {
      state.proposalCtes.items = [...state.proposalCtes.items, ...state.availableCtes.selected];
      state.availableCtes.items = state.availableCtes.items.filter(
        (availableCte) => !state.availableCtes.selected.some((selectedAvailableCte) => selectedAvailableCte.id === availableCte.id)
      );
      state.availableCtes.selected = [];
    },
    removeFromProposal: (state: NewContractProposalState) => {
      state.availableCtes.items = [...state.availableCtes.items, ...state.proposalCtes.selected];
      state.proposalCtes.items = state.proposalCtes.items.filter(
        (proposalCte) => !state.proposalCtes.selected.some((selectedProposalCte) => selectedProposalCte.id === proposalCte.id)
      );
      state.proposalCtes.selected = [];
    },
    selectFromAvailable: (state: NewContractProposalState, action) => {
      state.availableCtes.selected = [
        ...state.availableCtes.items.filter(({ id }) => action.payload.find((cteId: number) => id === cteId)),
      ];
    },
    selectFromProposal: (state: NewContractProposalState, action) => {
      state.proposalCtes.selected = [...state.proposalCtes.items.filter(({ id }) => action.payload.find((cteId: number) => id === cteId))];
    },
  },
});

export const { reset, setAvailableCtes, deleteCtes, addToProposal, removeFromProposal, selectFromAvailable, selectFromProposal } =
  contractSlice.actions;

export default contractSlice.reducer;
