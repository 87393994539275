import React from 'react';
import { Button } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router';

import BaseLayout from '../BaseLayout';

const LandingLayout = ({ children }: any) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const isAuthenticated = keycloak.authenticated;

  const renderLoginButton = () =>
    isAuthenticated ? (
      <Button color="primary" variant="contained" onClick={() => navigate('/logout')} sx={{ px: 2 }}>
        <ArrowBack sx={{ color: 'white', marginRight: 1 }} /> Sair
      </Button>
    ) : (
      <Button color="primary" variant="contained" onClick={() => navigate('/login')} sx={{ px: 2 }}>
        Entrar <ArrowForward sx={{ color: 'white', marginLeft: 1 }} />
      </Button>
    );

  return (
    <BaseLayout type="app" loginButton={renderLoginButton()}>
      {children}
    </BaseLayout>
  );
};

export default LandingLayout;
