import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      height: '100%',
    },
  })
);

export default useStyles;
