export enum UserRolesTypes {
  ADMIN = 'torqi_admin',
  CUSTOMER_SUPERVISOR = 'torqi_customer_supervisor',
  CUSTOMER_OPERATOR = 'torqi_customer_operator',
  CUSTOMER_OWNER = 'torqi_customer_owner',
}

export const userRolesTranslation = {
  // [UserRolesTypes.ADMIN]: 'Administrador Torqi',
  [UserRolesTypes.CUSTOMER_SUPERVISOR]: 'Supervisor',
  [UserRolesTypes.CUSTOMER_OPERATOR]: 'Operador',
  [UserRolesTypes.CUSTOMER_OWNER]: 'Supervisor Master',
};
