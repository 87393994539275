import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    container: {
      position: 'fixed',
      backgroundColor: theme.palette.common.black,
      height: '100%',
    },
    imgContainer: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 0,
    },
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      opacity: 0.4,
    },
  })
);

export default useStyles;
