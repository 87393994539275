import React from 'react';
import { CircularProgress, Grid } from '@mui/material';

import useStyles from './styles';

const PageFallback = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.container} data-testid="page-fallback">
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default PageFallback;
