import React, { useState } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { isUserAdmin, isUserCustomerOperator } from 'utils/auth';
import { ReactComponent as Logo } from 'assets/logo_white.svg';
import { navBarItems, NAVBAR_HEIGHT } from 'config/navbar';
import useStyles from '../styles';

interface BaseLayoutProps {
  type: 'app' | 'landing' | 'none';
  loginButton?: any;
  children: any;
  hideButtons?: boolean;
}

const BaseLayout = ({ type, children, loginButton, hideButtons = true }: BaseLayoutProps) => {
  const classes = useStyles();
  const theme = useTheme<any>();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navItems: any[] = navBarItems[type];
  const [itemSelected, setItemSelected] = useState('#main');

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          className={classes.navbar}
          zIndex={99}
          width="100%"
          height={75}
          padding="10px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="fixed"
        >
          <Grid container alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
            <Grid item height={isMobile ? 25 : 50}>
              <Link to="/">
                <Logo width="100%" height="100%" />
              </Link>
            </Grid>
            {!isMobile && (
              <Grid item display="flex" flexDirection="row" flexGrow={0.25} justifyContent="space-around">
                {navItems.length > 0 &&
                  hideButtons &&
                  navItems
                    .filter(({ href }) => !(!isUserAdmin() && href === '/admin'))
                    .filter(({ href }) => !(!isUserAdmin() && href === '/operacoes-secundarias'))
                    .filter(({ href }) => !(isUserCustomerOperator() && href === '/consulta-contratos'))
                    .map(({ label, href }) => {
                      const isNavItemSelected: boolean = type === 'app' ? location.pathname === href : itemSelected === href;
                      if (type === 'app') {
                        return (
                          <Link
                            key={label}
                            style={{ border: isNavItemSelected ? undefined : 'none' }}
                            className={classes.navLink}
                            to={href}
                          >
                            <Typography color="white" fontWeight={isNavItemSelected ? 'bold' : undefined}>
                              {label}
                            </Typography>
                          </Link>
                        );
                      }

                      return (
                        <AnchorLink
                          key={label}
                          style={{ border: isNavItemSelected ? undefined : 'none' }}
                          className={classes.navLink}
                          href={href}
                          onClick={() => setItemSelected(href)}
                          offset={75}
                        >
                          <Typography color="white" fontWeight={isNavItemSelected ? 'bold' : undefined}>
                            {label}
                          </Typography>
                        </AnchorLink>
                      );
                    })}
              </Grid>
            )}
            {loginButton}
          </Grid>
        </Box>
      </Grid>
      <Grid mt={`${NAVBAR_HEIGHT}px`} item height={`calc(100vh - ${NAVBAR_HEIGHT}px)`} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default BaseLayout;
