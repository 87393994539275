import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SecondaryOperationType } from 'common/secondary-operation.type';
import DateTime from 'utils/date';
import { IContractSecondaryOperation } from 'common/contract.interface';
import { ICounterparty } from 'common/secondary-operation.interface';
import { ICTeSecondaryOperation } from 'common/cte.interface';

export interface SecondaryOperationState {
  type: SecondaryOperationType | null;
  operationDate: DateTime;
  selectedCounterparty: ICounterparty | null;
  totalEmissionValue: number;
  totalOperationValue: number;
  auxiliaries: {
    availableContracts: IContractSecondaryOperation[];
    selectedContracts: IContractSecondaryOperation[];
    availableCTes: ICTeSecondaryOperation[];
    selectedCTes: ICTeSecondaryOperation[];
    counterparties: ICounterparty[];
  };
}

const initialState: SecondaryOperationState = {
  type: null,
  operationDate: DateTime.now(),
  selectedCounterparty: null,
  totalEmissionValue: 0.0,
  totalOperationValue: 0.0,
  auxiliaries: {
    availableContracts: [],
    selectedContracts: [],
    availableCTes: [],
    selectedCTes: [],
    counterparties: [],
  },
};

const secondaryOperationSlice = createSlice({
  name: 'secondaryOperation',
  initialState,
  reducers: {
    resetSecondaryOperation: () => {
      return initialState;
    },
    resetSecondaryOperationAuxiliaries: (state) => {
      state.auxiliaries = { ...initialState.auxiliaries, counterparties: state.auxiliaries.counterparties };
    },
    updateSecondaryOperation: (state, action: PayloadAction<Partial<SecondaryOperationState>>) => {
      const { payload } = action;

      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    updateSecondaryOperationAuxiliaries: (state, action: PayloadAction<Partial<SecondaryOperationState['auxiliaries']>>) => {
      state.auxiliaries = { ...state.auxiliaries, ...action.payload };
    },
    updateSecondaryOperationContract: (state, action: PayloadAction<IContractSecondaryOperation>) => {
      const { payload } = action;
      state.auxiliaries.availableContracts = state.auxiliaries.availableContracts.map((contract) =>
        contract.contractUniqueReference === payload.contractUniqueReference ? payload : contract
      );
      state.auxiliaries.selectedContracts = state.auxiliaries.selectedContracts.map((contract) =>
        contract.contractUniqueReference === payload.contractUniqueReference ? payload : contract
      );
    },
    setCounterparties: (state, action: PayloadAction<ICounterparty[]>) => {
      state.auxiliaries.counterparties = action.payload;
    },
  },
});

export const {
  resetSecondaryOperation,
  resetSecondaryOperationAuxiliaries,
  updateSecondaryOperation,
  updateSecondaryOperationAuxiliaries,
  updateSecondaryOperationContract,
  setCounterparties,
} = secondaryOperationSlice.actions;

export default secondaryOperationSlice.reducer;
