import { DateTime } from 'luxon';

DateTime.local({ locale: 'pt-br' });

export const defaultDateFormat = 'dd/MM/yyyy';
export const defaultTimeFormat = 'HH:mm:ss';

export const formatDate = (date?: string, format?: string): string => {
  if (!date) return '-';

  let datetimeDate;

  if (format) datetimeDate = DateTime.fromFormat(date, format);
  else datetimeDate = DateTime.fromISO(date);

  const formattedDate = datetimeDate.toFormat(defaultDateFormat);
  return datetimeDate.isValid && formattedDate;
};

export const formatDateWithTime = (
  date: string,
  options?: {
    dateFormat?: string;
    timeFormat?: string;
  }
) =>
  DateTime.fromISO(date, { zone: 'utc' })
    .setZone('UTC-3')
    .toFormat(`${options?.dateFormat || defaultDateFormat} - ${options?.timeFormat || defaultTimeFormat}`);

export const formatTime = (time: string, options?: { fromFormat?: string; toFormat?: string }) =>
  DateTime.fromFormat(time, options?.fromFormat || defaultTimeFormat).toFormat(options?.toFormat || 'HH:mm');

export const calculateDateFromNow = (daysDifference: number) => DateTime.now().plus({ days: daysDifference });

export const getDaysDiff = (initialDate: DateTime, finalDate: DateTime) => initialDate.diff(finalDate, 'days').days;

export const overDuedDays = (isoDate: string) => {
  const diffDays = Math.ceil(getDaysDiff(DateTime.fromISO(isoDate), DateTime.now()));
  if (diffDays < 0) {
    return diffDays * -1;
  }

  return '-';
};

export default DateTime;
