import React from 'react';
import { Grid, Typography } from '@mui/material';
import WipImg from 'assets/images/wip.png';

import useStyles from './styles';

const PageWip = ({ children }: any) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" alignContent="center" className={classes.container}>
      <div className={classes.imgContainer}>
        <img alt="ZRP work on it" src={WipImg} className={classes.img} />
      </div>

      <Grid zIndex={1} item xs={12} alignItems="center" display="flex" flexDirection="column">
        <Typography variant="h2" component="h1" color="white" marginTop={10}>
          Em breve...
        </Typography>

        {children}
      </Grid>
    </Grid>
  );
};

export default PageWip;
