import { ICarrierBranch, ICarrierRoot } from './carrier.interface';

export default interface IUser {
  id?: string;
  email: string;
}
export enum RegisterStatusTypes {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  INACTIVE = 'INACTIVE',
}
export const registerStatusTypesPtbr = {
  [RegisterStatusTypes.PENDING]: 'Pendente',
  [RegisterStatusTypes.ACTIVE]: 'Ativo',
  [RegisterStatusTypes.REJECTED]: 'Rejeitado',
  [RegisterStatusTypes.INACTIVE]: 'Inativo',
};
export interface IUserRegistry {
  id: number;
  documentNumber: string;
  name: string;
  phoneNumber: string;
  registerStatusType: RegisterStatusTypes;
  email: string;
  birthDate?: string;
  businessRole?: string;
  roles?: string[];
  role?: string;
  carrierRoot?: ICarrierRoot;
  carrierBranch?: ICarrierBranch;
  carrierRootId?: number;
  carrierBranchId?: number;
  isFirstAccess: boolean;
}
