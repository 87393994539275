import { Button } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Link, Navigate, Routes as ReactRoutes, Route } from 'react-router-dom';

import { AuthenticatedArea, PrivateRoute } from 'components/AuthenticatedArea';
import PageFallback from 'components/PageFallback';
import PageWip from 'components/PageWip';
import LandingLayout from 'layout/LandingLayout';

import { UserRolesTypes } from 'common/user-role-types';
import AdminRoutes from 'views/admin/AdminRoutes';

/** Views */
const Onboarding = lazy(() => import('views/landing/Onboarding'));
const AuthLogin = lazy(() => import('views/dashboard/public/Login'));
const AuthLogout = lazy(() => import('views/dashboard/public/Logout'));
const ForgotPassword = lazy(() => import('views/dashboard/public/ForgotPassword'));
const WelcomePage = lazy(() => import('views/dashboard/private/WelcomePage'));
const UserRegistration = lazy(() => import('views/dashboard/private/UserRegistration'));
const UploadAndContract = lazy(() => import('views/dashboard/private/UploadAndContract'));
const ContractConfirmation = lazy(() => import('views/dashboard/private/ContractConfirmation'));
const Contracts = lazy(() => import('views/dashboard/private/Contracts'));
const ContractDetail = lazy(() => import('views/dashboard/private/ContractDetail'));
const CompleteRegistration = lazy(() => import('views/dashboard/public/CompleteRegistration'));
const Reports = lazy(() => import('views/dashboard/private/ReportsPanel'));
const RevenueReport = lazy(() => import('views/dashboard/private/RevenueReport'));
const ConciliationDetails = lazy(() => import('views/dashboard/private/RevenueReport/ConciliationDetails'));
const CorrectionReport = lazy(() => import('views/dashboard/private/CorrectionReport'));

const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageFallback />}>
        <ReactRoutes>
          {/* Public Routes */}
          <Route
            path="/cadastro"
            element={
              <LandingLayout>
                <Onboarding />
              </LandingLayout>
            }
          />
          <Route path="/login" element={<AuthLogin />} />
          <Route path="/logout" element={<AuthLogout />} />
          <Route path="/recuperar-senha" element={<ForgotPassword />} />
          <Route path="/finalizar-cadastro" element={<CompleteRegistration />} />
          <Route
            path="/soon"
            element={
              <PageWip>
                <Link to="/" style={{ textDecoration: 'none', marginTop: 10 }}>
                  <Button variant="contained" color="secondary">
                    Ir para início
                  </Button>
                </Link>
              </PageWip>
            }
          />

          {/* Private Routes */}
          <Route element={<AuthenticatedArea />}>
            <Route path="/bem-vindo" element={<WelcomePage />} />
            <Route path="/primeiro-acesso/usuarios/novo" element={<UserRegistration />} />
            <Route path="/contratar" element={<UploadAndContract />} />
            <Route path="/confirmacao" element={<ContractConfirmation />} />
            <Route path="/consulta-contratos" element={<Contracts />} />
            <Route path="/contratos/:id" element={<ContractDetail />} />
            <Route element={<PrivateRoute permittedRoles={[UserRolesTypes.ADMIN]} />}>
              <Route path="/admin/*" element={<AdminRoutes />} />
            </Route>

            <Route path="/relatorios" element={<Reports />} />
            <Route path="/relatorios/repasses" element={<RevenueReport />} />
            <Route path="/relatorios/repasses/:revenueId" element={<ConciliationDetails />} />
            <Route path="/relatorios/ajustes" element={<CorrectionReport />} />
          </Route>

          {/* Default Route match */}
          <Route path="*" element={<Navigate to="/login" />} />
        </ReactRoutes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
