const colors = {
  red: '#c1272d',
  lightRed: '#c1272d26',
  yellow: '#f9b200',
  golden: '#bc8813',
  purple: '#72354e',
  lightYellow: '#fef0cc',
  confirmGreen: '#23c48a',
  lightGreen: '#23c38b1d',
  orange: '#f27606',
  lightOrange: '#f2760638',
  blue: '#1976d2',
  lightBlue: '#1976d21d',
  black: '#000000',
  grey: '#9e9e9e',
  cardGrey: '#faf9f9',
  columnGrey: '#f8f8f8',
  white: '#fff',
};

const palette = {
  primary: colors.black,
  secondary: colors.red,
  lightSecondary: colors.lightRed,
  yellow: colors.yellow,
  lightYellow: colors.lightYellow,
  green: colors.confirmGreen,
  lightGreen: colors.lightGreen,
  orange: colors.orange,
  lightOrange: colors.lightOrange,
  error: colors.red,
  blue: colors.blue,
  lightBlue: colors.lightBlue,
  black: colors.black,
  grey: colors.grey,
  cardGrey: colors.cardGrey,
  columnGrey: colors.columnGrey,
  white: colors.white,
  purple: colors.purple,
  golden: colors.golden,
};

export { colors, palette };
