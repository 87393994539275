import { combineReducers, configureStore } from '@reduxjs/toolkit';

import auth from './auth';
import contract from './contract';
import newContractProposal from './newContractProposal';
import user from './user';
import secondaryOperation from './secondary-operation';

export const authStore = configureStore({ reducer: auth });

const reducer = combineReducers({
  contract,
  newContractProposal,
  auth,
  user,
  secondaryOperation,
});

export type IRootState = ReturnType<typeof reducer>;
export default reducer;
