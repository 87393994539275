import { createTheme } from '@mui/material';
import { palette } from 'themes/colors';

export function theme() {
  const {
    primary,
    secondary,
    lightSecondary,
    lightYellow,
    yellow,
    grey,
    cardGrey,
    columnGrey,
    black,
    white,
    orange,
    lightOrange,
    error,
    blue,
    lightBlue,
    purple,
    golden,
  } = palette;

  return {
    customColors: {
      orange: {
        main: orange,
        light: lightOrange,
      },
      error: {
        main: error,
      },
      blue: {
        main: blue,
        light: lightBlue,
      },
      purple: {
        main: purple,
      },
      golden: {
        main: golden,
      },
    },
    ...createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              borderBottom: 'none',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 40,
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: '0.75rem',
              whiteSpace: 'nowrap',
            },
            sizeSmall: {
              padding: '4px 20px',
              borderWidth: 1,
              '&:hover': {
                borderWidth: 1,
              },
            },
            sizeMedium: {
              padding: '12px 40px',
            },
            sizeLarge: {
              padding: 'clamp(5px, 12px, 12px) clamp(20px, 64px, 64px)',
              fontSize: 20,
            },
            outlined: {
              border: `2px solid ${primary}`,
              boxShadow: 'none',
              '&:hover': {
                border: `2px solid ${primary}`,
                backgroundColor: primary,
                color: '#fff',
              },
            },
            contained: {
              color: 'white',
              ':disabled': {
                color: 'white',
                backgroundColor: grey,
              },
              '&:hover': {
                boxShadow: '0px 4px 5px rgba(0,0,0,0.5)',
              },
            },
          },
        },
      },
      palette: {
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
          light: lightSecondary,
        },
        background: {
          default: white,
        },
        warning: {
          main: yellow,
          light: lightYellow,
        },
        info: {
          main: grey,
        },
        grey: {
          100: cardGrey,
          200: columnGrey,
          500: grey,
        },
      },
      typography: {
        fontFamily: 'Poppins, sans-serif',
        h6: {
          fontWeight: 400,
          color: black,
          fontSize: '1rem',
        },
        h5: {
          fontSize: '1.125rem',
          color: black,
          fontWeight: 400,
        },
        h4: {
          fontSize: '1.25rem',
          color: black,
          fontWeight: 500,
        },
        h3: {
          fontSize: '1.5rem',
          color: black,
          fontWeight: 600,
        },
        h2: {
          fontSize: '2rem',
          color: black,
          fontWeight: 600,
        },
        h1: {
          fontSize: '2.2rem',
          color: black,
          fontWeight: 600,
        },
        subtitle1: {
          fontSize: '1.25rem',
          fontWeight: 400,
          color: black,
          lineHeight: '1.643em',
        },
        subtitle2: {
          fontSize: '1rem',
          fontWeight: 400,
          color: grey,
        },
        caption: {
          fontSize: '0.9rem',
          color: black,
          fontWeight: 300,
        },
        body1: {
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: '1.643em',
        },
        body2: {
          letterSpacing: '0em',
          fontWeight: 400,
          lineHeight: '1.643em',
        },
      },
    }),
  };
}

export default theme;
