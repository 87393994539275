import React from 'react';
import { Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router';
import BaseLayout from '../BaseLayout';

const LandingLayout = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <BaseLayout
      type="landing"
      loginButton={
        <Button color="primary" variant="contained" onClick={() => navigate('/login')} sx={{ px: 2 }}>
          Entrar <ArrowForward sx={{ color: 'white', marginLeft: 1 }} />
        </Button>
      }
      hideButtons={location.pathname !== '/cadastro'}
    >
      {children}
    </BaseLayout>
  );
};

export default LandingLayout;
