import React, { useEffect } from 'react';
import { ThemeProvider, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import keycloak from 'config/keycloak';
import theme from 'themes';
import { retrieveAuthFromLocalStorage } from 'stores/auth';
import { StandaloneSnackbarConfigurator } from 'components/StandaloneSnackbar/StandaloneSnackbar';
import './App.scss';
import Routes from './Routes';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveAuthFromLocalStorage());
  }, []);

  return (
    <ThemeProvider theme={theme()}>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        iconVariant={{
          info: (
            <CircularProgress
              size={15}
              sx={{
                marginRight: 4,
              }}
              color="primary"
            />
          ),
        }}
      >
        <StandaloneSnackbarConfigurator />
        <ReactKeycloakProvider authClient={keycloak}>
          <Routes />
        </ReactKeycloakProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
