import { lazy } from 'react';

const Atypicality = lazy(() => import('./Atypicality'));
const ContractTakersNotification = lazy(() => import('./ContractTakersNotification'));
const LimitsConcentration = lazy(() => import('./LimitsConcentration'));
const Registers = lazy(() => import('./Registers'));

export const ContractAnalysis = {
  Atypicality,
  ContractTakersNotification,
  LimitsConcentration,
  Registers,
};
