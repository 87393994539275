import { UserRolesTypes } from 'common/user-role-types';
import keycloak from 'config/keycloak';

export const allPermissionRoles = [
  UserRolesTypes.ADMIN,
  UserRolesTypes.CUSTOMER_OPERATOR,
  UserRolesTypes.CUSTOMER_SUPERVISOR,
  UserRolesTypes.CUSTOMER_OWNER,
];

export const getUserRoles = () => {
  const { tokenParsed } = keycloak;
  const removedRoles = ['default-roles-torqi', 'uma_authorization'];
  return tokenParsed?.roles.filter((role: string) => !removedRoles.includes(role)) || [];
};

export const userHasPermissions = (permissions: UserRolesTypes[]): boolean => {
  return getUserRoles().some((role) => permissions.includes(role as any));
};

export const isUserAdmin = (): boolean => userHasPermissions([UserRolesTypes.ADMIN]);
export const isUserCustomer = (): boolean =>
  userHasPermissions([UserRolesTypes.CUSTOMER_OPERATOR, UserRolesTypes.CUSTOMER_SUPERVISOR, UserRolesTypes.CUSTOMER_OWNER]);
export const isUserCustomerSupervisor = (): boolean => userHasPermissions([UserRolesTypes.CUSTOMER_SUPERVISOR]);
export const isUserCustomerOperator = (): boolean => userHasPermissions([UserRolesTypes.CUSTOMER_OPERATOR]);
export const isUserCustomerOwner = (): boolean => userHasPermissions([UserRolesTypes.CUSTOMER_OWNER]);
