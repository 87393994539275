import { createSlice } from '@reduxjs/toolkit';
import { ISimulationSubmit } from 'common/contract-simulation.interface';

export interface ContractState {
  simulationSubmit: ISimulationSubmit;
}

const initialState: ContractState = {
  simulationSubmit: {
    contractSimulation: {
      paidAmount: 0,
      anticipatedAmount: 0,
      baseInterest: 0,
      dueDays: 0,
      paymentDueIn: '',
      submissionDate: '',
      ctesLiquidValues: {},
    },
    loading: false,
  },
};

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setSimulationSubmit: (state: ContractState, action) => {
      state.simulationSubmit = { ...state.simulationSubmit, ...action.payload };
    },
    setLoadingCalc: (state: ContractState, action) => {
      state.simulationSubmit.loading = action.payload;
    },
  },
});

export const { setSimulationSubmit, setLoadingCalc } = contractSlice.actions;

export default contractSlice.reducer;
