import Keycloak from 'keycloak-js';

const keycloakUrl = process.env.REACT_APP_KEYCLOAK_SERVER_URL || '';
const keycloakRealm = process.env.REACT_APP_KEYCLOAK_REALM || '';
const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID || '';

const keycloak = new Keycloak({
  url: keycloakUrl,
  realm: keycloakRealm,
  clientId: keycloakClientId,
});

export default keycloak;
